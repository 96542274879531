<template>
  <div :class="`bg-white d-flex flex-column ${isWide ? 'px-30 py-20' : 'p-10'}`">
    <span :class="`font-bold center ${isMobile ? 'mt-3 font-25' : 'mt-5 font-40'}`">{{ $t('community_guidelines') }}</span>
    <div :class="`d-flex flex-column ${isWide ? 'm-30 font-25' : isMobile ? 'font-15' : 'm-10 font-20'}`">
      <span :class="isWide ? 'mt-10' : 'mt-5'">eventbox ist eine Plattform, auf der Nutzer ihre Kreativität ausdrücken können. Hier kommen Menschen aus der ganzen Welt zusammen, um lustige Erfahrungen und Erlebnisse auszutauschen.</span>
      <span :class="isWide ? 'mt-10' : 'mt-5'">Die eventbox Gemeinschaftsrichtlinien sind ein wichtiger Verhaltensbestandteil unserer App, um eine sichere und freundliche Umgebung für alle Mitglieder unserer Community zu schaffen.</span>
      <span :class="isWide ? 'mt-10' : 'mt-5'">Die Verletzung dieser kann dazu führen, dass Ihr Konto und/oder Inhalt entfernt wird.</span>
      <span :class="isWide ? 'mt-10' : 'mt-5'">Darüber hinaus müssen unsere Nutzer die geltenden Gesetze ihres jeweiligen Landes befolgen.</span>
      <span :class="isWide ? 'mt-10' : 'mt-5'">Bei eventbox respektieren sich alle User untereinander.</span>
      <span :class="isWide ? 'mt-10' : 'mt-5'">Wir vertrauen darauf, dass alle Nutzer sich gegenseitig respektieren, auf eventbox sind folgende Inhalte nicht erlaubt.</span>

      <span :class="`font-bold ${isWide ? 'mt-20 font-30' : 'mt-10 font-20'}`">Schädigender oder gefährlicher Inhalt</span>
      <div :class="isMobile ? 'ml-5' : 'ml-10'">
        <ul>
          <li>
            Terrororganisationen, Hass-Gruppen und andere kriminelle Organisationen oder Einzelpersonen dürfen nicht auf eventbox aktiv sein.
          </li>
          <li>
            Veröffentlichen, teilen oder senden Sie KEINE gewalttätigen oder grafischen Inhalte oder stellen Sie keine Inhalte bereit, die andere zu Gewalttaten aufrufen.
          </li>
          <li>
            Veröffentlichen, teilen oder senden Sie KEINE Inhalte, die gefährliche Handlungen, Selbstverletzung oder Selbstmord darstellen und stellen Sie KEINE Inhalte bereit, die andere dazu ermutigen, solche Aktivitäten nachzuahmen.
          </li>
          <li>
            Posten, teilen oder senden Sie KEINE Inhalte, die eventbox strikt untersagt. Dies sind beispielsweise Inhalte, die sexuelle Übergriffe, sexuellen Missbrauch, sexuelle Ausbeutung oder sexuelle Gewalt zeigen, dazu aufrufen, Nutzer zu diesen Taten ermutigen oder auch Tipps für Essstörungen geben.
          </li>
          <li>
            Bedrohen Sie NIEMALS andere Menschen oder schüchtern diese ein. Auch sind keine Androhungen physischer Gewalt gegen bestimmte Personen erlaubt.
          </li>
          <li>
            Verwenden Sie eventbox NICHT, um Waffen, Bomben, Drogen oder andere illegale Güter zu verkaufen oder zu bewerben, die durch nationale Gesetze verboten sind.
          </li>
          <li>
            Veröffentlichen, teilen oder senden Sie KEINE Inhalte, die in Verbindung mit illegalen Aktivitäten stehen oder diese darstellen.
          </li>
        </ul>
      </div>

      <span :class="`font-bold ${isWide ? 'mt-20 font-30' : 'mt-10 font-20'}`">Diskriminierung oder Hassreden</span>
      <div :class="isMobile ? 'ml-5' : 'ml-10'">
        <ul>
          <li>
            Veröffentlichen, teilen oder senden Sie KEINE Inhalte, die Hass auf eine Gruppe von Menschen aufgrund ihrer Rasse, Ethnie, Religion, Nationalität, Kultur, Behinderung, sexuellen Orientierung, Geschlecht, Geschlechtsidentität, Alter oder einer anderen Diskriminierung schüren.
          </li>
        </ul>
      </div>

      <span :class="`font-bold ${isWide ? 'mt-20 font-30' : 'mt-10 font-20'}`">Missbrauch oder sexuelle Aktivitäten</span>
      <div :class="isMobile ? 'ml-5' : 'ml-10'">
        <ul>
          <li>
            eventbox verbietet strikt jegliche Inhalte, die sexuelle Gewalt, sexuellen Missbrauch oder sexuelle Ausbeutung bildlich darstellen. Veröffentlichen Sie keinen solchen Inhalt.
          </li>
          <li>
            Veröffentlichen, teilen oder senden Sie KEINE sexualisierten Inhalte und/oder Inhalte, die nackte Menschen abbilden, sowie Prostitution, oder Sexhandel
          </li>
        </ul>
      </div>

      <span :class="`font-bold ${isWide ? 'mt-20 font-30' : 'mt-10 font-20'}`">Kinder- und Jugendschutz</span>
      <div :class="isMobile ? 'ml-5' : 'ml-10'">
        <ul>
          <li>
            eventbox verbietet ausdrücklich die Veröffentlichung sexuell eindeutiger Inhalte, in denen Minderjährige vorkommen oder sexuell missbraucht werden.
          </li>
          <li>
            Posten, teilen oder senden Sie keine Inhalte, die Online-Dating mit Minderjährigen thematisieren - oder auch Mobbing, Verletzung der Privatsphäre eines Minderjährigen, Selbstmord, Selbstverletzung, Essstörungen oder andere Inhalte abbilden, die die körperliche und geistige Gesundheit von Minderjährigen gefährden könnten.
          </li>
          <li>
            Nutzen Sie die App NICHT, wenn Sie unter 13 Jahre alt sind, oder das festgelegte Mindestalter für Ihr Land oder Ihre Region unterschreiten. (Eltern, bitte klicken Sie hier für weitere Informationen.)
          </li>
        </ul>
      </div>

      <span :class="`font-bold ${isWide ? 'mt-20 font-30' : 'mt-10 font-20'}`">Belästigung oder Cybermobbing</span>
      <div :class="isMobile ? 'ml-5' : 'ml-10'">
        <ul>
          <li>
            Schreiben Sie KEINE öffentlichen Beiträge und / oder private Nachrichten, um andere Personen zu belästigen.
          </li>
          <li>
            Erniedrigen, demütigen, diffamieren oder schikanieren Sie KEINE anderen Personen oder ermutigen Sie NICHT andere Benutzer, dies zu tun.
          </li>
        </ul>
      </div>

      <span :class="`font-bold ${isWide ? 'mt-20 font-30' : 'mt-10 font-20'}`">Identitätswechsel, Betrug, Spam oder andere irreführende Inhalte</span>
      <div :class="isMobile ? 'ml-5' : 'ml-10'">
        <ul>
          <li>
            Geben Sie sich nicht als eine andere Person oder Organisation aus, die Sie de facto nicht sind - indem Sie falsche Identitäten erstellen und andere dazu verleiten, zu glauben, dass Ihre Beziehung zu einer anderen Person oder Organisation der Realität entspricht. Veröffentlichen Sie KEINE irreführenden Inhalte, um Geld zu verdienen.
          </li>
          <li>
            Posten Sie keine Inhalte oder nehmen Handlungen vor, die andere Nutzer unserer Community schädigen. Dazu zählen betrügerische und in sonstiger Weise irreführende Handlungen gegenüber anderen Nutzern sowie Phishing Aktivitäten.
          </li>
        </ul>
      </div>

      <span :class="`font-bold ${isWide ? 'mt-20 font-30' : 'mt-10 font-20'}`">Geistiges Eigentum</span>
      <div :class="isMobile ? 'ml-5' : 'ml-10'">
        <ul>
          <li>
            Veröffentlichen, teilen oder senden Sie KEINE Inhalte, die Urheberrechte, Markenrechte oder andere Rechte an geistigem Eigentum verletzen.
          </li>
        </ul>
      </div>

      <span :class="`font-bold ${isWide ? 'mt-20 font-30' : 'mt-10 font-20'}`">Darüber hinaus verbieten unsere Richtlinien Aktivitäten, die den TikTok-Dienst beeinträchtigen:</span>
      <div :class="isMobile ? 'ml-5' : 'ml-10'">
        <ul>
          <li>
            Behindern Sie NICHT den täglichen Betrieb von eventbox, indem Sie Webseiten oder zugehörige Netzwerke hacken und vermeiden Sie Maßnahmen, die den App-Zugriff von Nutzern beschränken.
          </li>
          <li>
            Verteilen Sie KEINE Dateien, die Viren, Trojaner oder andere schädliche Software enthalten.
          </li>
          <li>
            Das Modifizieren, Anpassen, Übersetzen, Zurückentwickeln, Disassemblieren, Dekompilieren oder auf eventbox basierende abgeleitete Produkte zu erstellen, ist NICHT erlaubt einschließlich aller Dateien, Tabellen oder Dokumentationen, Quellcodes, Algorithmen, Methoden oder Techniken.
          </li>
        </ul>
      </div>

      <span :class="isWide ? 'mt-10' : 'mt-5'">Danke, dass Sie Teil der Community sind und dazu beitragen, eine sichere und angenehme Plattform für alle Nutzer zu schaffen. Wenn Sie Inhalte sehen, von denen Sie glauben, dass sie gegen die Gemeinschaftsrichtlinien verstoßen, bitte melden Sie sie, damit wir diese überprüfen und die entsprechenden Maßnahmen ergreifen können.</span>
      <span :class="isWide ? 'mt-10' : 'mt-5'">Sollten Sie weitere Fragen und/oder Anregungen haben, wenden Sie sich bitte an <a href="mailto:info@eventboxde.com">info@eventboxde.com</a></span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CommunityGuidelines',
  computed: {
    isMobile() {
      return this.$store.state.isMobile;
    },
    isWide() {
      return this.$store.state.isWide;
    }
  }
};
</script>